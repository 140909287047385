import mixpanel, { Dict } from 'mixpanel-browser'

import { env } from './env'

export enum TrackingEvent {
  // routing
  pageView = 'PAGE_VIEW',
  // topbar
  logoClicked = 'TOPBAR_LOGO_CLICKED',
  backToPatientListClicked = 'TOPBAR_BACK_TO_PATIENT_LIST_CLICKED',
  navPatientClicked = 'TOPBAR_NAV_PATIENT_CLICKED',
  patientSearchEntered = 'TOPBAR_PATIENT_SEARCH_ENTERED',
  patientSearchSuggestionSelected = 'TOPBAR_PATIENT_SEARCH_SUGGESTION_SELECTED',
  profileMenuClicked = 'TOPBAR_PROFILE_MENU_CLICKED',
  profileMenuSettingsClicked = 'TOPBAR_PROFILE_MENU_SETTINGS_CLICKED',
  profileMenuSignoutClicked = 'TOPBAR_PROFILE_MENU_SIGNOUT_CLICKED',
  settingsMenuClicked = 'TOPBAR_SETTINGS_MENU_CLICKED',
  settingsMenuAddPatientClicked = 'TOPBAR_SETTINGS_MENU_ADD_PATIENT_CLICKED',
  settingsMenuArchiveClicked = 'TOPBAR_SETTINGS_MENU_ARCHIVE_CLICKED',
  settingsMenuImportPatientsClicked = 'TOPBAR_SETTINGS_MENU_IMPORT_PATIENTS_CLICKED',
  settingsMenuManualOrdersClicked = 'TOPBAR_SETTINGS_MENU_MANUAL_ORDERS_CLICKED',
  settingsMenuCareSummaryReportClicked = 'TOPBAR_SETTINGS_MENU_CARE_SUMMARY_REPORT_CLICKED',
  settingsMenuSiteMgmtClicked = 'TOPBAR_SETTINGS_MENU_SITE_MGMT_CLICKED',
  settingsMenuUserMgmtClicked = 'TOPBAR_SETTINGS_MENU_USER_MGMT_CLICKED',
  settingsMenuRoleMgmtClicked = 'TOPBAR_SETTINGS_MENU_ROLE_MGMT_CLICKED',
  settingsMenuUserGuideClicked = 'TOPBAR_SETTINGS_MENU_USER_GUIDE_CLICKED',
  settingsMenuEmailSupportClicked = 'TOPBAR_SETTINGS_MENU_EMAIL_SUPPORT_CLICKED',
  settingsMenuReleaseInfoClicked = 'TOPBAR_SETTINGS_MENU_RELEASE_INFO_CLICKED',
  settingsMenuAnalyticsClicked = 'TOPBAR_SETTINGS_MENU_ANALYTICS_CLICKED',
  // patient list
  patientListPatientClicked = 'PATIENT_LIST_PATIENT_CLICKED',
  patientListSettingsClicked = 'PATIENT_LIST_SETTINGS_CLICKED',
  patientListSettingsSelectColumnsClicked = 'PATIENT_LIST_SETTINGS_SELECT_COLUMNS_CLICKED',
  patientListFilterUpdateClicked = 'PATIENT_LIST_FILTER_UPDATE_CLICKED',
  patientListFilterAddClicked = 'PATIENT_LIST_FILTER_ADD_CLICKED',
  // patient actions
  patientSubscribeClicked = 'PATIENT_SUBSCRIBE_CLICKED',
  patientOpenZendeskClicked = 'PATIENT_OPEN_ZENDESK_CLICKED',
  // quick notes
  quickNotesAddClicked = 'QUICK_NOTE_ADD_CLICKED',
  quickNotesChangeClicked = 'QUICK_NOTE_CHANGE_CLICKED',
  // result details
  resultCardClicked = 'RESULT_CARD_CLICKED',
  resultChartRangeClicked = 'RESULT_CHART_RANGE_CLICKED',
  resultAddValueClicked = 'RESULT_ADD_VALUE_CLICKED',
  resultListAlertClicked = 'RESULT_LIST_ALERT_CLICKED',
  resultListDeleteValueClicked = 'RESULT_LIST_DELETE_VALUE_CLICKED',
  resultListReviewClicked = 'RESULT_LIST_REVIEW_CLICKED',
  resultReviewAlertsClicked = 'RESULT_REVIEW_ALERTS_CLICKED',
  resultSummaryIntervalClicked = 'RESULT_SUMMARY_INTERVAL_CLICKED',
  resultSummaryIntervalInitialized = 'RESULT_SUMMARY_INTERVAL_INITIALIZED',
  // devices
  deviceAssignClicked = 'DEVICE_ASSIGN_CLICKED',
  deviceAssignConfirmClicked = 'DEVICE_ASSIGN_CONFIRM_CLICKED',
  deviceOrderClicked = 'DEVICE_ORDER_CLICKED',
  deviceOrderConfirmClicked = 'DEVICE_ORDER_CONFIRM_CLICKED',
  deviceDeleteClicked = 'DEVICE_DELETE_CLICKED',
  deviceDeleteOrderClicked = 'DEVICE_DELETE_ORDER_CLICKED',
  // demographics
  demographicsPatientStatusClicked = 'DEMOGRAPHICS_PATIENT_STATUS_CLICKED',
  demographicsMuteNotificationsClicked = 'DEMOGRAPHICS_MUTE_NOTIFICATIONS_CLICKED',
  // documents
  documentsEscalationDownloadClicked = 'DOCUMENTS_ESCALATION_DOWNLOAD_CLICKED',
  documentsConsentDownloadClicked = 'DOCUMENTS_CONSENT_DOWNLOAD_CLICKED',
  // program
  programChangeClicked = 'PROGRAM_CHANGE_CLICKED',
  // notes
  notesSelectTemplateClicked = 'NOTES_SELECT_TEMPLATE_CLICKED',
  notesSaveClicked = 'NOTES_SAVE_CLICKED',
  notesDeleteClicked = 'NOTES_DELETE_CLICKED',
  notesEditClicked = 'NOTES_EDIT_CLICKED',
  notesEditSaveClicked = 'NOTES_EDIT_SAVE_CLICKED',
  notesEnhanceClicked = 'NOTES_ENHANCE_CLICKED',
  notesInsertMeasurementsClicked = 'NOTES_INSERT_MEASUREMENTS_CLICKED',
  // care summary report
  careSummaryReportPreviewClicked = 'CARE_SUMMARY_REPORT_PREVIEW_CLICKED',
  careSummaryReportPublishClicked = 'CARE_SUMMARY_REPORT_PUBLISH_CLICKED',
  careSummaryReportDownloadClicked = 'CARE_SUMMARY_REPORT_DOWNLOAD_CLICKED',
  // settings
  settingsLanguageChangeClicked = 'SETTINGS_LANGUAGE_CHANGE_CLICKED',
  settingsThemeChangeClicked = 'SETTINGS_THEME_CHANGE_CLICKED',
  settingsUnitChangeClicked = 'SETTINGS_UNIT_CHANGE_CLICKED',
  settingsChangePasswordConfirmClicked = 'SETTINGS_CHANGE_PASSWORD_CONFIRM_CLICKED',
  settingsDigestChanged = 'SETTINGS_DIGEST_CONFIGURATION_CHANGED',
  // release info
  releaseInfoPreviousClicked = 'RELEASE_INFO_PREVIOUS_CLICKED',
  // epic sso
  authEpicSSOLaunchRequest = 'AUTH_EPIC_SSO_LAUNCH_REQUEST',
  authEpicSSOOauth = 'AUTH_EPIC_SSO_OAUTH',
  authEpicSSOSuccess = 'AUTH_EPIC_SSO_SUCCESS',
  // care tasks
  careTaskCompleteClicked = 'CARE_TASK_COMPLETE_CLICKED',
  careTaskOpenClicked = 'CARE_TASK_OPEN_CLICKED',
  careTaskSwapWindowClicked = 'CARE_TASK_SWAP_WINDOW_CLICKED',
  // analytics
  analyticsProgramSelected = 'ANALYTICS_PROGRAM_SELECTED',
  analyticsAggregationLevelSelected = 'ANALYTICS_AGGREGATION_LEVEL_SELECTED',
  analyticsDateSelected = 'ANALYTICS_DATE_SELECTED',
  analyticsCsvDownloadClicked = 'ANALYTICS_CSV_DOWNLOAD_CLICKED',
  analyticsBackClicked = 'ANALYTICS_BACK_CLICKED',
  analyticsEditClicked = 'ANALYTICS_EDIT_CLICKED',
  analyticsSaveClicked = 'ANALYTICS_SAVE_CLICKED',
  analyticsAddClicked = 'ANALYTICS_ADD_CLICKED',
  analyticsGridChanged = 'ANALYTICS_GRID_CHANGED',
}

const hasMixpanelKey = !!env.PUBLIC_TUNA_MIXPANEL_KEY

export function trackEvent(eventName: TrackingEvent, payload?: Dict): void {
  if (hasMixpanelKey) {
    mixpanel.track(eventName, payload)
  }
}

export function identify(id: string): void {
  if (hasMixpanelKey) {
    mixpanel.identify(id)
    mixpanel.people.set('name', id)
  }
}

export function resetTracking(): void {
  if (hasMixpanelKey) {
    mixpanel.reset()
  }
}
