import { faCircleExclamationCheck, faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { AlertReviewTypeEnum } from '@vetahealth/tuna-can-api'
import { Button, Modal, Popconfirm } from 'antd'
import i18next from 'i18next'
import React, { useEffect, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { ResultsAlertType, isAlertUnreviewed, useResultsStore } from '../../../../../stores/results'
import { EscalateNote } from '../../../Notes'

type ReviewState = { isConfirmVisible: boolean; isReviewVisible: boolean; isEscalateVisible: boolean }

const initialState: ReviewState = {
  isConfirmVisible: false,
  isReviewVisible: false,
  isEscalateVisible: false,
}

export function Review({ id, type }: { id: number; type: ResultsAlertType }): JSX.Element | null {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [alerts, reviewAlerts] = useResultsStore((state) => [state.alerts, state.reviewAlerts])
  const [isLoading, withLoading] = useLoading()
  const [{ isConfirmVisible, isReviewVisible, isEscalateVisible }, set] = useReducer(
    (state: ReviewState, update: Partial<ReviewState>) => ({ ...state, ...update }),
    initialState,
  )
  const isRendered = useRef(true)

  const eventAlerts = alerts?.[type]?.[id] || []

  useEffect(() => {
    return () => {
      isRendered.current = false
    }
  }, [])

  function resetState(): void {
    set(initialState)
  }

  function handleReview(reviewType: AlertReviewTypeEnum): () => void {
    return () => {
      trackEvent(TrackingEvent.resultListReviewClicked, { reviewType })
      if (patient && eventAlerts) {
        withLoading(reviewAlerts(patient.id, { ids: eventAlerts.map(({ id }) => id), reviewType })).finally(resetState)
      }
    }
  }

  function handleAlerts(): void {
    if (eventAlerts.length > 0) {
      trackEvent(TrackingEvent.resultListAlertClicked)
      set(eventAlerts.some(isAlertUnreviewed) ? { isReviewVisible: true } : { isConfirmVisible: true })
    }
  }

  if (!eventAlerts.length) return null

  return (
    <>
      <Popconfirm
        title={i18next.t('widgets.results.confirmEnable')}
        okText={i18next.t('widgets.results.withdrawReview')}
        okButtonProps={{ loading: isLoading }}
        onConfirm={handleReview('withdrawn')}
        cancelText={t('actions.cancel')}
        onCancel={() => set(initialState)}
        cancelButtonProps={{ type: 'text' }}
        placement="topRight"
        open={isConfirmVisible}
        icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
      >
        <Button
          shape="circle"
          icon={
            <FontAwesomeIcon
              icon={faCircleExclamationCheck}
              color={eventAlerts.some(isAlertUnreviewed) ? Colors.ruby600 : Colors.gray600}
            />
          }
          type="text"
          onClick={handleAlerts}
        />
      </Popconfirm>
      <Modal
        title={i18next.t('widgets.results.confirmSuppress')}
        open={isReviewVisible}
        onCancel={resetState}
        centered
        footer={
          <>
            <Button
              loading={isLoading && isEscalateVisible}
              key="escalate"
              type="primary"
              onClick={() => set({ isEscalateVisible: true })}
            >
              {t('actions.escalated')}
            </Button>
            <Button
              loading={isLoading && !isEscalateVisible}
              key="review"
              type="primary"
              onClick={handleReview('self')}
            >
              {t('actions.reviewed')}
            </Button>
          </>
        }
      />
      <EscalateNote
        isVisible={isEscalateVisible}
        onCancel={() => set({ isEscalateVisible: false })}
        onSuccess={handleReview('escalation')}
      />
    </>
  )
}
